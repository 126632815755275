<template>
    <div class="personal-info">
        <profile-card />
    </div>
</template>

<script>
import ProfileCard from '@/components/profile/ProfileCard.vue';

export default {
    name: 'personal-info',
    components: { ProfileCard },
};
</script>
